import React from 'react'
import ProductCardSimple from './ProductCardSimple'

export default function ProductListSimple({ apiResponse }) {


    return (
        <div className='product-list-simple'>
            {Object.entries(apiResponse.data.list.ideas).map(([ideaKey, ideaValue], index) => (
                <div key={index} className='product-list-item'>
                    <h2>{ideaValue}</h2>
                    <a target="_blank" href={`https://www.amazon.de/s?k=${ideaValue}&tag=mrshop05-21`}><span>Produkte auf Amazon</span></a>
                </div>
            ))}
        </div>
    );
}

import React from 'react';
import axios from 'axios';

export default function API({
    hobby,
    life,
    char,
    art,
    cat,
    priceFrom,
    priceTo,
    age,
    version,
    issue,
    type,
    nextStep,
    email,
    onApiResponseTwoNew,
    handleApiResponseTwoNew,
    newsletterSelected,
    onClick,
    disabled

}) {
    const sendMessage = (e) => {
        e.preventDefault();
        nextStep();
        onClick();
        console.log(email)
        axios.post('https://product-scraper-ggbm.onrender.com', {
            age: `${age}`,
            hobby: `${hobby}`,
            life: `${life}`,
            char: `${char}`,
            art: `${art}`,
            cat: `${cat}`,
            priceFrom: `${priceFrom}`,
            priceTo: `${priceTo}`,
            type: `${type}`,
            issue: `${issue}`,
            email: `${email}`,
            version: `${version}`,
            name: `hello`,
            newsletter: `${newsletterSelected}`
        })
            .then(function (response) {

                handleApiResponseTwoNew(response);

            })
            .catch(function (error) {
                console.log(error);
            });

    }


    return (
        <button disabled={disabled} className='api-button single' onClick={sendMessage}>
            <span className='btn-span-container'>
                <span>nur Geschenk-Ideen suchen!<span className='duration-btn'>(ca. 30 Sekunden)</span></span>
            </span>
        </button>
    );
}


import React from 'react'

export default function ProductCard({ product }) {

 // Überprüfen und kürzen Sie den Produkttitel, wenn er länger als 15 Zeichen ist
 const originalTitle = product.title;
 let title = '';

 if (originalTitle.length > 15) {
     const words = originalTitle.split(' ');

     for (let i = 0; i < words.length; i++) {
         if ((title + words[i]).length <= 15 || i < 7) { // Wörter hinzufügen, solange sie unter 15 Zeichen liegen oder es weniger als 5 Wörter gibt
             title += ' ' + words[i];
         } else {
             title += '...';
             break;
         }
     }
 } else {
     title = originalTitle;
 }

 return (
     
     <div className="product-card">
         <a target="_blank" rel="noopener noreferrer" href={`${product.link}&tag=mrshop05-21`}>
         <img src={product.image} alt={title.trim()} />
         <h2>{title.trim()}</h2>
         {product.prices && product.prices[0] && product.prices[0].raw && <p>{product.prices[0].raw}</p>}
         </a>
     </div>
    
 );
}

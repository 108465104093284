import React, { useState, useRef, useEffect } from 'react';
import Form from './configurator-components/Form';

export default function Configurator() {
  const [step, setStep] = useState(1);
  const containerRef = useRef(null);

  useEffect(() => {
    const sendHeight = () => {
      if (window.parent) {
        window.parent.postMessage({ height: containerRef.current.offsetHeight }, '*');
        window.parent.postMessage({ height: containerRef.current.offsetHeight }, '*'); // send height twice
      }
    };
  
    // Initialize mutation observer
    const observer = new MutationObserver(() => {
      // Call sendHeight twice when DOM changes
      sendHeight();
      sendHeight();
    });
  
    // Observe changes
    observer.observe(containerRef.current, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true
    });
  
    // Call initially
    sendHeight();
    sendHeight(); // send height twice initially
  
    // Call every time the window is resized
    window.addEventListener('resize', () => {
      sendHeight();
      sendHeight(); // send height twice on resize
    });
  
    // Cleanup after component unmounts
    return () => {
      window.removeEventListener('resize', sendHeight);
      observer.disconnect();
    };
  }, []);
  

  useEffect(() => {
    const productContainer = document.querySelector('.product-container-single');
    if (productContainer) {
      productContainer.style.marginTop = '0';
    }
  }, []);

  return (
    <div className='configurator-container' ref={containerRef}>
      <Form step={step} setStep={setStep} />
    </div>
  );
}

import React from 'react'
import axios from 'axios';


export default function APIV2({
    hobby,
    life,
    char,
    art,
    cat,
    priceFrom,
    priceTo,
    age,
    version,
    onClick,
    issue,
    type,
    nextStep,
    email,
    onApiResponse,
    newsletterSelected,
    handleApiResponse,
    disabled
}) {



    const sendMessage = (e) => {
        e.preventDefault();
        nextStep();
        onClick();
        axios.post('https://product-scraper-ggbm.onrender.com', {
            age: `${age}`,
            hobby: `${hobby}`,
            life: `${life}`,
            char: `${char}`,
            art: `${art}`,
            cat: `${cat}`,
            priceFrom: `${priceFrom}`,
            priceTo: `${priceTo}`,
            type: `${type}`,
            issue: `${issue}`,
            email: `${email}`,
            version: `${version}`,
            name: `hello`,
            newsletter: `${newsletterSelected}`
        })
        .then(function (response) {
     console.log(response.data)
            handleApiResponse(response);

        })
        .catch(function (error) {
            console.log(error);
        });
        
    }





  return (
    <button disabled={disabled} className='api-button' onClick={sendMessage}>
        <span className='btn-span-container'>
         <span>Geschenkideen & -produkte suchen!<span className='duration-btn'> (2-3 Minuten)</span></span>
        </span>
 </button>
  )
}


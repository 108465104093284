import React, { useState, useEffect } from 'react'
import ProductCard from './ProductCard';

export default function ProductList({ apiResponse }) {

    const [visibleCounts, setVisibleCounts] = useState(apiResponse.data.map(() => 2));

    useEffect(() => {
        // Update visibleCounts when apiResponse changes
        setVisibleCounts(apiResponse.data.map(() => 2));
    }, [apiResponse]);

    const loadMore = (index) => {
        // Überprüfen, ob es weitere Produkte zum Laden gibt
        if (visibleCounts[index] < apiResponse.data[index].results.length) {
            setVisibleCounts(visibleCounts.map((count, i) => i === index ? count + 10 : count));
        }
    }

    const handleLoadMore = (event, index) => {
        event.preventDefault();
        loadMore(index);
    }

    return (
        <div>
            {apiResponse.data.map((idea, index) => (
                idea.results.length > 0 && (
                    <div key={index} className="product-container-single">
                        <div className="idea-heading-container">
                            <h2>{idea.idea}</h2>
                        </div>
                        <div className='product-container-child'>
                        {idea.results.slice(0, visibleCounts[index]).map((product, i) => (
                            <ProductCard key={i} product={product} />
                        ))}
                        </div>
                        {idea.results.length > 2 && visibleCounts[index] < idea.results.length && (
                            <div className="product-load-more">
                            <button onClick={(event) => handleLoadMore(event, index)}>Mehr laden</button>
                            </div>
                        )}
                    </div>
                )
            ))}
        </div>
    );
}
